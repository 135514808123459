/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap');


/* 
Font headings is 




Font Paragraph/Details is 


*/
.border{
  border: 1px solid red;
}
.link {
  text-decoration: none;
  color: black;
}
/************ Default Css ************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  /* border: 1px solid red; */
}

.inter-headings-h1 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-size: 44px;
  line-height: 60px;
  text-decoration: none solid white;
  text-align: start;
  overflow-wrap: break-word;
}

.inter-headings-h2 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-decoration: none solid rgb(22, 22, 22);
  text-align: start;
  overflow-wrap: break-word;
}
.inter-headings-h2-light {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  text-decoration: none solid rgb(22, 22, 22);
  text-align: start;
  overflow-wrap: break-word;
}


.inter-headings-h3 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-decoration: none solid rgb(22, 22, 22);
  text-align: start;
  overflow-wrap: break-word;
}
.inter-headings-h3-light {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
  text-decoration: none solid rgb(22, 22, 22);
  text-align: start;
  overflow-wrap: break-word;
}

.inter-details {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  vertical-align: baseline;
  text-decoration: none solid rgb(65, 62, 62);
  text-align: start;
  overflow-wrap: break-word;
}

@media (max-width : 600px) {
  .inter-headings-h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 30px;
  }
  
  .inter-headings-h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
  }
  .inter-headings-h2-light {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
  }
  
  
  .inter-headings-h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .inter-headings-h3-light {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
  }
  
  .inter-details {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
}


.container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
}
.section{
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
  padding: 50px 150px;
  box-sizing: content-box;
}

li{
  /* font-size: 1.2em; */
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m9 5 7 7-7 7"/></svg>');
  /* <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
</svg> */
  
transition: all 0.2s ease;

}

li:hover{
  font-weight: 600;
}
li:nth-child(4n + 1) {
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24"><path stroke="rgb(35,190,190)" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m9 5 7 7-7 7"/></svg>');
}

li:nth-child(4n + 2) {
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24"><path stroke="rgb(140,198,71)" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m9 5 7 7-7 7"/></svg>');
}

li:nth-child(4n + 3) {
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24"><path stroke="rgb(244,147,30)" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m9 5 7 7-7 7"/></svg>');
}
li:nth-child(4n) {
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24"><path stroke="rgb(249,182,21)" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m9 5 7 7-7 7"/></svg>');
}



li:nth-child(4n + 1):hover{
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24"><path stroke="rgb(35,190,190)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 12H5m14 0-4 4m4-4-4-4"/></svg>');
}
li:nth-child(4n + 2):hover{
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24"><path stroke="rgb(140,198,71)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 12H5m14 0-4 4m4-4-4-4"/></svg>');
}
li:nth-child(4n + 3):hover{
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24"><path stroke="rgb(244,147,30)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 12H5m14 0-4 4m4-4-4-4"/></svg>');
}
li:nth-child(4n):hover{
  list-style-image: url('data:image/svg+xml,<svg class="w-6 h-6 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24"><path stroke="rgb(249,182,21)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 12H5m14 0-4 4m4-4-4-4"/></svg>');
}


li::marker{
  font-size: 3.6em;
  line-height: .01;
}

@media (max-width : 425px) {
  .section{
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 25px;

  }

}

.btn{
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: #ffc86b;
  font-weight: 600;
  letter-spacing: -0.05em;
  background-color: #34353a;
  padding: 0.5em 1em;
  border: 2px solid #ffc86b;
  border-radius: 6px;
  box-shadow: 0 5px 20px #ffc96b47;
  transition: transform 0.3s;
}
.btn:hover{
  transform: translateY(-3px);
}

img,picture,video,canvas,svg{
  display: block;
  max-width: 100%;
  user-select: none;
}

@media (max-width: 475px) {
  .container{
      max-width: 475px;
  }
}
@media (max-width: 640px) {
  .container{
      max-width: 640px;
  }
}
@media (max-width: 768px) {
  .container{
      max-width: 768px;
  }
}
@media (max-width: 1024px) {
  .container{
      max-width: 1024px;
  }
}
@media (max-width: 1280px) {
  .container{
      max-width: 1280px;
  }
}
@media (max-width: 1536px) {
  .container{
      max-width: 1536px;
  }
}