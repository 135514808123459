  .exp-old-container {
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 100px;
  }
  .exp-old-container h2{
    text-align: center;
  }
  .exp-old-container p{
    text-align: center;
  }
  .exp-image-container {
    margin-top: 50px;
    display: flex;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }
  
  .exp-image-old-exp {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .exp-image-card-old-exp {
    position: relative;
    /* width: 25%; */
    transition: width 0.5s ease;
    overflow: hidden;
    /* border-radius: 20px 25px 25px 20px; */
    /* margin: 20px; */
  }
  
  .exp-overlay-old-exp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay-content-old-exp {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-section1 {
    align-items: center;
    padding: 30px;
    text-align: right;
  }
  
  .text-section2 {
    align-items: center;
    padding: 30px;
    text-align: left;
  }
  
  .text-section1 ul,
  .text-section2 ul {
    list-style: none;
    /* font-weight: 800; */
    padding: 0;
    margin: 0;
  }
  
  .text-section1 li,
  .text-section2 li {
    margin-top: 40px;
    margin-bottom: 40px;
    list-style: none;
  }
  .text-section1 li:hover,
  .text-section2 li:hover{
    list-style: none;
  }

  .exp-card-icon{
    font-size: 32px;
    margin-bottom: 10px;
  }
  
  .exp-overlay-old-exp h3 {
    /* position: absolute; */
    text-align: center;
    align-self: center;
    /* margin: 10px 0; */
  }
  
  .exp-overlay-old-exp h3.active-label {
    position: relative;
  }
  
  .exp-overlay-old-exp h3.unactive-label {
    /* bottom: 20px; */
    font-size: 1.5rem;
    transform: rotate(90deg);
    transition: 0.5s;
  }
  .slider-controls{
    display: none;
  }

  @media (max-width : 600) {
    .exp-old-container{
      flex-direction: column;
    }    
  }
  
  @media (max-width: 768px) {
    .exp-image-container {
      height: 70vh; /* Adjust height for mobile view */
      /* border: 2px solid blue; */
      position: relative;
    }
    .exp-old-container p{
      padding: 0 20px;
    }
  
    .exp-image-card-old-exp {
      width: 100%;
      flex-shrink: 0;
    }
  
    .exp-image-card-old-exp.active-old-exp-cards,
    .exp-image-card-old-exp.unactive-old-exp-cards {
      width: 100% !important;
    }
    .setRedBorder{
        border: 1px solid blue;
    }
  
    /* .text-section1,
    .text-section2 {
      padding: 10px;
    }
  
    .text-section1 li,
    .text-section2 li {
      margin-top: 20px;
      margin-bottom: 20px;
    } */
  
    /* Hide slider controls on desktop */
    .slider-controls {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin: 20px 0;
      /* height: 100px; */
    }
  
    /* Show slider controls only on mobile */
    /* .slider-controls button {
       display: none; 
    } */
    .mobile-visible-old-exp-card{
      display: block;
    }
  
    .slider-controls button {
      display: block;
      border: none;
      border: 2px solid rgb(255, 255, 255);
      background-color: #f4931e;
      color: white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      
    }
    .overlay-content-old-exp{
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid green;
    }
    .overlay-content-old-exp h3{
        order: -1;       
        font-size: 1.5rem; 
    }
    .text-section1 , .text-section2{
        text-align: left;
        padding: 0px;
        padding-left: 20px;
        margin: 0;
    }
    .text-section1 li,
    .text-section2 li {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 12px;
      /* font-weight: 400; */
    } 
  
    /* Hide all cards except the current slide on mobile */
    .exp-image-card-old-exp {
      display: none;
      width: 100% !important;
    }
  
    .mobile-visible-old-exp-card {
      display: block;
    }
  }
  