@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.footer-icons {
  font-size: 32px;
  background-color: #8cc647;
  border-radius: 50%;
  padding: 10px;
}



.cta-section {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 40%,
    #809936 100%
  );
  /* height: 90vh; */
  position: relative;
  padding: 20px;
  text-align: center;
}

.cta-header {
  padding: 50px 0;
  /* margin-bottom: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cta-header hr {
  width: 30%;
  margin-bottom: 30px;
  /* height: 30%; */
}

.cta-title {
  color: white;
  font-size: 36px;
  margin-bottom: 20px;
}

.cta-talk-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.cta-logo{
    position: absolute;
    top: 0%;
    left: 0;
    width: 20%;
}
.socials {
    position: absolute;
    top: 10%;
    right: 3%;
    /* width: 100%; */
    display: flex;
    gap: 10px;
    justify-content: flex-start;
  }



.cta-free-trial {
  display: flex;
}

/* .cta-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 14px;
  } */
.cta-footer-left{
    /* border: 1px solid red; */
    display: flex;
    width: 20%;
    padding-bottom: 50px;
    padding-left: 50px;
    align-items: flex-end;
}
/* .cta-footer-left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4931e;
  color: white;
  width: 400px;
  border-radius: 25px 20px;
  padding: 20px;
  margin-bottom: 30px;
} */

.cta-footer-left h2 {
  font-size: 24px;
  text-align: center;
}

.fa {
  padding: 10px;
  font-size: 16px;
  width: 35px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}
.fa:hover {
  opacity: 0.7;
}
.fa-facebook {
  background: #3b5998;
  color: white;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
}
.fa-instagram {
  background: #125688;
  color: white;
}

.cta-footer hr {
  width: 100%;
}

.cta-footer-left-container {
  width: 400px;
  height: 150px;
  text-align: left;
}

.social-icons i {
  /* font-size: 24px; */
  margin-right: 10px;
  cursor: pointer;
}

.country-select {
  margin-top: 20px;
}

.country-select select {
  padding: 10px;
  font-size: 14px;
}

.cta-footer-right {
  margin-left: 20px;
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
  /* gap: 100px; */
  width: 100%;
  color: white;
}

.cta-footer-right div {
  margin: 10px;
  text-align: left;
}
.resources .link{
    color: white;
}
.about .link{
    color: white;
}
.resources ul,
.support ul,
.about ul {
  list-style-type: none;
  margin-top: 10px;
  padding: 0;
}

.resources ul li,
.support ul li,
.about ul li {
    cursor: pointer;
  list-style: none;
  margin-bottom: 8px;
}
.resources ul li:hover,
.support ul li:hover,
.about ul li:hover {
  list-style: none;
  margin-bottom: 8px;
}

.headquarters {
  text-align: left;
}

.headquarters h3 {
  margin-bottom: 10px;
}

.headquarters p {
  margin: 5px 0;
}
.cta-section hr{
    margin-bottom: 20px;
}


@media (max-width : 600px) {
    .socials{
        top: 3%;
    }
    .cta-logo{
        top: -3%;
        width: 40%;
    }
    .cta-footer-right{
        flex-direction: column;
    }
}