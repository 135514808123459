@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");



.navbar-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0px 20px 0px 20px;
  z-index: 9999;
  width: 100%;
  height: 10vh;
  transform: translateY(-100%);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.navbar-container.visible {
  transform: translateY(0);
}

.navbar-container.scrolled {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  height: 100%;
}

.navbar-logo img {
  height: 100%;
  transition: filter 0.3s;
}

.navbar-links {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
}
.dropbtn:hover{
  background-color: #f9b615;
  border-radius: 20px;
}

.navbar-container.scrolled .dropbtn {
  color: rgba(0, 0, 0, 0.63);
}

.dropdown-container {
  position: absolute;
  top: 40px; /* Adjust as needed */
  /* left: 53%; */
  /* width: 20%; */
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: -8px 8px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  border-radius: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.dropdown-left,
.dropdown-right {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.dropdown-left-full{
  width: 100%;
}



.dropdown-item,
.dropdown-subitem {
  padding: 12px 10px;
  /* display: block; */
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.dropdown-item:hover,
.dropdown-subitem:hover {
  background-color: #f9b615;
  border-radius: 20px 20px 20px 20px;
  color: white;
}

.dropdown-placeholder {
  padding: 12px 16px;
  color: gray;
}

.contact-button {
  text-decoration: none;
  background-color: white;
  color: #1d1d47;
  text-align: center;
  align-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: #45a049;
}

.navbar-container.scrolled .contact-button {
  background-color: #4caf50;
  color: white;
}

.navbar-container.scrolled .contact-button:hover {
  background-color: #45a049;
}

.hamburger-menu {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}
.hamburger-menu-green{
  display: none;
  color : #4caf50;
  display: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .navbar-logo{
    padding: 10px 0;
  }
  .navbar-links {
    display: none;
  }
  .hamburger-menu {
    display: block;
    color: white;
  }
  .hamburger-menu-green {
    display: block;
  }
}

.mobile-menu {
  position: fixed;
  /* border: 1px solid red; */
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  z-index: 10000;
  padding: 0px 50px;
}

.mobile-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  /* border: none; */
  /* border-bottom: 1px solid black; */
  padding: 10px 0px;
  /* font-size: 24px; */
  cursor: pointer;
  box-sizing: border-box;
  width: calc(100vw - 100px);
  text-align: start;
  color: black;
}
.mobile-menu-tabs{
  border-top: 1px solid black;
}
.mobile-menu-tabs:nth-child(1){
  border-top: none; 
  /* background-color: red; */
}
.nav-dropdown-icons{
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  color: #45a049;
  /* border: 1px solid red; */
}


.mobile-submenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.mobile-submenu-item {
  background: none;
  /* border: none; */
  padding: 5px 0px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: start;
  color: gray;
}

.mobile-contact-button {
  background-color: #4caf50;
  color: white;
  text-align: center;
  align-content: center;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.mobile-menu-item {
  text-decoration: none;
  font-size: 16px;
  color: black;
}
.mobile-dropdown-subitem{
  text-decoration: none;
  color: rgb(99, 99, 99);
  font-size: 14px;
  /* margin-left: 15px; */
  padding: 6px 15px;
  display: block;
}

.mobile-contact-button:hover {
  background-color: #45a049;
}

.close-btn-mobile-menu {
  position: absolute;
  right: 20px;
  top: 0;
}
