.Snap-Container {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    /* background-color: blue; */
    background-color: white;
    height: 100%;
    position: relative;
  }
  .background{
    position: absolute;
    width: 100%;
    height: 90vh;
    /* border: 1px solid yellow; */
    transform: rotateY(180deg);
  }
 
  .circle {
    width: 30%;
    height: 100%;
    z-index: 3;
  }

  
  
  .circle-content {
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* list-style: none; */
    height: 90vh;
    /* border: 1px solid red; */
  }
   .circle-content hr{
    height: 30px;
    width: 5px;
    border: none;
    background-color: #8cc647;
   }
  .circle .list{
    background-color: #23bebe; 
    border: 5px solid #6fa72b;
    width: 200px;
    height: 90px;
    display: flex;
    color: white;
    cursor: pointer;
    /* font-size: 10px; */
    /* font-size: 16px; */
    justify-content: center;
    text-align: center;
    align-items: center;
    /* border-radius: 20px 25px 20px 25px; */
    /* border-radius: 50%; */
    
    /* margin: 20px; */
  }
  .glow{
    text-shadow: 0 0 2px #23bebe;
  }
  
  .circle .list.highlight {
    background-color: #4bdbdb;
  }
  .circle-content .list.left-margin {
    /* background-color: red;  */
    border-radius: 0px 25px 0px 25px;
    margin-left: 100px;
  }
  .circle-content .list.right-margin {
    /* background-color: red;  */
    border-radius: 25px 0px 25px 0px;
    margin-right: 100px;
  }
  .content {
    /* border: 2px solid blue; */
    width: 70%;
    z-index: 2;
    /* height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory; */
    /* overflow-y: auto; */
  }
  
  .details-snap {
    width: 100%;
    height: 90vh;
    color: black;
    /* scroll-snap-align: start; */
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* color: white; */
    width: 100%;
  }
  .details-snap h3{
    padding-left: 50px;
    padding-bottom: 20px;
    font-size: 1.5rem;
  }
  .details-snap p{
    padding-left: 50px;
    padding-bottom: 10px;
    width: 70%;
    /* font-size: 1.2rem; */
  }
  .details-snap .points-snap{
    padding-left: 80px;   
    /* font-size: 1.1rem;  */
  }
  .details-snap .sub-points{
    padding-left: 50px;    
  }
  .nextSection {
    height: 10vh;
    width: 100%;
  }


  @media (max-width : 640px) {
    .Snap-Container{
        flex-direction: column;
        background-color: #eaeaea;
    }
    .circle{
        width: 100%;
        height: 20vh;
    }
    .circle-content{
        flex-direction: row;
        height: 20vh;
    }
    .circle-content .list{
        width: 60px;
        height: 60px;
        border: 2px solid #8cc647;
        font-size: 8px;
        line-height: 10px;
    }
    .circle-content hr{
        width: 30px;
        height: 5px;
    }
    .content{
      /* display: flex; */
      width: 100%;

      /* display: none; */
      /* flex-direction: column; */
    }
    .details-snap{
      /* height: auto; */
      background-color: #eaeaea;
      padding: 50px 0;
      
    }
    .details-snap h3{
      padding-left: 20px;
    }
    
    .circle{
      /* display: none; */
      padding: 0;
    }
    .details-snap p{
      padding-left: 20px;
      padding-bottom: 10px;
      width: 100%;
      /* font-size: 1.2rem; */
    }
    .circle-content .list.left-margin {
      /* background-color: red;  */
      border-radius: 50%;
      margin-left: 0px;
    }
    .circle-content .list.right-margin {
      /* background-color: red;  */
      border-radius: 50%;
      margin-right: 0px;
    }
    .pin-snap{
      background-color: #eaeaea;
      padding: 0;
    }
    .background{
      display: none;
    }
  }
  