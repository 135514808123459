.information-form-section {
  width: 100%;
  background-image: url('../assets//img/world.png');
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 15vh;
  height: 160vh;
}

.information-form-container {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  max-width: 600px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.information-form-container h2 {
  font-size: 1.5rem;
  color: #f4931e;
  margin-bottom: 0.5rem;
}

.information-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.3rem;
  color: #f4931e;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.info-button {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.info-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .information-form-container {
      padding: 1.5rem;
  }

  .information-form-container h2 {
      font-size: 1.2rem;
  }

  .info-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
  }
}
