.tech-top-details{
    display:  flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.tech-top-details hr{
    width: 200px;
    /* height: 5px; */
    border: 2px solid #f4931e;
    /* color: ; */
}

.tech-icons{
    display: grid;
    grid-template-columns: repeat(5,calc(20% - 0.5px));
    grid-template-rows: repeat(2,150px);
    background-color: #f4931e;
    gap: 1px;
}
.technology{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.technology:hover{
    background-color: #f9f9f9;
}

@media (max-width : 680px) {
    .tech-icons{
        grid-template-rows: repeat(5,calc(20% - 0.5px));
        grid-template-columns: repeat(2,50%);
    }
    .technology{
        padding: 10px;
    }
}