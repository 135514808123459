/* @import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css'); */

.card-slider{
    padding: 0 70px;
}
.card-slider h2{
    text-align: center;
    margin-bottom: 20px;
}
.card-slider p{
    text-align: center;
    padding: 0 60px;
}
.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 70vh; */
    /* margin-bottom: 100px; */
    
}

.CardSliderContainer {
    display: flex;
    overflow: hidden;
    width: 90%;
}

.card {
    /* flex: 1 0 25%; */
    padding: 10px;
    /* width: cal(25% - 10px); */
    /* width: 25%; */
    height: 450px;
    /* perspective: 1000px; Required for 3D transforms */
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}
.card:hover .card-front{
    z-index: -22;
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    /* border: 1px solid violet; */
    transition: transform 0.8s;
}

.card-front {
    background-size: cover;
    background-position: center;
    position: relative;
    text-decoration: none;
}

.card-front .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.card-back {
    background-color: #050505;
    transform: rotateY(180deg);
    color: white;
    top: 0; /* Ensure the card-back starts from the top */
    left: 0; /* Ensure the card-back starts from the left */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.card-front h3 {
    z-index: 10;
    color: white;
    /* font-size: 1.5rem; */
    text-align: center;
    padding: 20px;
    /* text-decoration: none; */

    box-sizing: border-box;
    text-shadow: 0 0 5px #23bebe;
}
.card .link{
    text-decoration: none;
}

.card-back p {
    /* font-size: 0.8rem; */
    color: #ffffff;
    text-align: left;
    white-space: pre-line;
    padding: 10px;
    margin: 0;
}
.card-back h3{
    /* font-size: 1.5rem; */
    color: #ffffff;
    /* text-align: left; */
    padding: 10px;
    margin: 0;
}

.card-back .inter-details-list{
    padding-left: 20px;
}

.slider-btn {
    background-color: #59ac16;
    border: none;
    border-radius: 50%;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 16px;
}

.slider-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

@media (max-width: 1200px) {
    .card {
        flex: 1 0 20%; /* 4 cards in a row for medium screens */
    }
}

@media (max-width: 900px) {
    .card {
        flex: 1 0 33.333%; /* 3 cards in a row for small screens */
    }
}

@media (max-width: 600px) {
    .cards-slider-title p{
        padding: 0 5px;
    }
    .slider-btn{
        font-size: 8px;
        padding: 0;
    }
    .card-back p{
        line-height: 1.1;
    }
    .card {
        flex: 1 0 50%; /* 2 cards in a row for very small screens */
        padding: 0;
    }
}

@media (max-width: 425px) {
    .card-slider{
        padding: 0 0px;
    }
    .CardSliderContainer{
        /* border: 1px solid red; */
        width: 100% ;
    }
    .slider-container{
        position: relative;
        /* border: 1px solid blue; */
    }
    .slider-btn{
        position: absolute;
        /* top: 0; */
        /* left: 0; */
        z-index: 1;
    }
    .slider-btn.left{
        left: 0;
        /* background-color: red; */
        /* top: 0; */
    }
    .slider-btn.right{
        /* background-color: red; */
        right: 0;
    }
    .card {
        flex: 1 0 100%; /* 1 card in a row for extra small screens */
    }
    .card-back{
        padding: 50px;
    }
    
}
