/* Slider.css */
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .slider {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    background-size: cover;
    background-color: rgba(35, 35, 46, 0.3);
    background-position: center;
    /* z-index: -6; */
  }
  .slide-media-video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
  }
  .slide-media-img{
    z-index: -6;
  }
  .slide.active {
    opacity: 1;
  }
  
  .fade {
    opacity: 0;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(56, 56, 90, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: -4; */
  }
  .overlay-slider{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: flex-end; */
  }
  .overlay-slider .h1{
    /* position: absolute ;
    top: 80%; */
    /* transform:  translateY(-50%); */
  }
  
  .img-text {
    text-align: start;
    position: absolute;
    top: 55%;
    left: 5%;
    /* display: flex; */
    /* justify-content: flex-start; */
    /* font-size: 2rem; */
    color: white;
    padding: 0 0px;
  }
  .slider-social-icons{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /* width: 80px; */
    padding: 10px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 0 25px 25px 0;
    /* border: 1px solid red; */
  }
  .slider-social-icons i:hover{
    /* transform: scale(1.5); */
    transform: translateX(10px) scale(1.5);
  }
  
  @media (max-width: 1024px) {
    .img-text {
      font-size: 1.8rem;
    }
  
    .selectors .number {
      font-size: 1rem;
      padding: 8px 14px;
    }
  
    .selectors button {
      font-size: 1rem;
    }
  }
  
  /* @media (max-width: 768px) {
    .img-text {
      font-size: 1.5rem;
    }
  
    .selectors .number {
      font-size: 0.9rem;
      padding: 6px 12px;
    }
  
    .selectors button {
      font-size: 0.9rem;
    }
  } */
  
  @media (max-width: 768px) {
    .img-text {
      font-size: 1.2rem;
      padding: 0 50px;
    }
    .container{
      height: 70vh;
    }
  
    .selectors {
      width: 33.33%;
      padding-top: 10px;
    }
  
    .selectors .number {
      font-size: 0.8rem;
      padding: 5px 10px;
    }
  
    .selectors button {
      display: none;
    }
  }
  
  .slider-nav {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 90%;
    border-top: 2px solid white;
  }
  
  .selectors {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 25%;
    /* padding-top: 20px; */
    margin-top: 20px;
    padding: 5px;
    cursor: pointer;
    transition: border-top 0.3s ease;
  }
  
  .selectors.active {
    /* border-top: 3px solid white; */
    background-color: #d3f3d53b;
    border-radius: 20px 25px 20px 25px;
  }
  
  .selectors .number {
    border: 3px solid white;
    border-radius: 50%;
    padding: 10px 18px;
    color: white;
    font-size: 12px;
    height: 45px;
    width: 45px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .selectors button {
    background-color: transparent;
    color: white;
    font-size: larger;
    font-weight: 600;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: color 0.3s ease;
  }
  
  .selectors button:hover {
    color: #45a049;
  }
  
  @media (max-width: 480px) {
    .slider-nav {
      width: 100%;
      bottom: 20px;
    }
  
    .selectors {
      width: 33.33%;
      padding-top: 10px;
    }
  
    .selectors .number {
      font-size: 0.8rem;
      padding: 5px 10px;
      border: 2px solid white;
    }
  
    .selectors.active .number {
      background-color: white;
      color: black;
    }
  }
  